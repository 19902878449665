export const PUBLIC_URL = '';

export const RoutePaths = {
    index: `${PUBLIC_URL}/`,

    services: `${PUBLIC_URL}/services`,
    about: `${PUBLIC_URL}/about`,
    calendar: `${PUBLIC_URL}/calendar`,
    news: `${PUBLIC_URL}/news`,
    contacts: `${PUBLIC_URL}/contacts`,
};
